<template>
  <div style="min-height: 700px">
    <BreadCrumbs>خانه > پشتیبانی</BreadCrumbs>
    <transition enter-active-class="animate__animated animate__fadeIn"
                appear>
      <div>
    <v-container class="pa-0">

      <div v-if="!html" class="supportnotice">
        ....
      </div>
      <div v-html="html" class="supportnotice"></div>


    </v-container>

    <v-container class="px-0 d-flex flex-wrap mt-10">

      <div class="supp-col">
<!--        <ContantBlock class="mb-8" >
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon10.png"/>
          <span slot="title" >پشتیبانی تلفنی</span>
          <div slot="contant" >
            <div class="d-flex justify-space-between align-center">
            <p class="ma-0" style="font-size: 16.4px">امور مشتریان</p>
            <v-btn
                height="32"
                class="btn"
                style="font-size: 14.57px !important;"
            >
              5000000 - 021
            </v-btn>
            </div>
            <div class="d-flex mt-6 justify-space-between align-center mb-5">
              <p class="ma-0" style="font-size: 16.4px">امور فنی و کاربری</p>
              <v-btn
                  height="32"
                  class="btn"
                  style="font-size: 14.57px !important;"
              >
                5000000 - 021
              </v-btn>
            </div>
          </div>
        </ContantBlock>-->
        <ContantBlock class="mb-8" >
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon6.png"/>
          <span slot="title" >شبکه های اجتماعی</span>
          <div slot="contant" class="pb-6">

            <div class="d-flex flex-column align-center">
              <a v-if="whatsapp" :href="'https://wa.me/'+whatsapp" target="_blank" class="supportsocial rounded-pill mb-3">
              <p class="ma-0 ml-9" style="border-color: #29b300;color: #29b300">Whatsapp</p>
              <img class="supportsocialimg" src="../assets/image/Public Icons/Social/whatsapp.png" height="48.8" width="48.8"/>
            </a>
              <a v-if="telegram" :href="'https://t.me/'+telegram" target="_blank" class="supportsocial rounded-pill  mb-3" style="border-color: #4ea4f6;color: #4ea4f6">
                <p class="ma-0 ml-9">Telegram</p>
                <img class="supportsocialimg" src="../assets/image/Public Icons/Social/telegram.png" height="48.8" width="48.8"/>
              </a>
              <a v-if="instagram" :href="'https://www.instagram.com/'+instagram" target="_blank" class="supportsocial rounded-pill  mb-3" style="border-color: #c03099;color: #c03099">
                <p class="ma-0 ml-9">Instagram</p>
                <img class="supportsocialimg" src="../assets/image/Public Icons/Social/instagam.png" height="48.8" width="48.8"/>
              </a>
              <a v-if="facebook" :href="'https://www.facebook.com/'+facebook" target="_blank" class="supportsocial rounded-pill  mb-3" style="border-color: #062580;color: #062580">
                <p class="ma-0 ml-9">Facebook</p>
                <img class="supportsocialimg" src="../assets/image/Public Icons/Social/facebook.png" height="48.8" width="48.8"/>
              </a>
              <a v-if="aparat" :href="'https://www.aparat.com/'+aparat" target="_blank" class="supportsocial rounded-pill mb-3" style="border-color: #a12631;color: #a12631">
                <p class="ma-0 ml-9">Aparat</p>
                <img class="supportsocialimg" src="../assets/image/Public Icons/Social/aprat.png" height="48.8" width="48.8"/>
              </a>
              <a v-if="linkedin" :href="'https://www.linkedin.com/'+linkedin" target="_blank" class="supportsocial rounded-pill mb-3" style="border-color: #1863b8;color: #1863b8">
                <p class="ma-0 ml-9">Linkedin</p>
                <img class="supportsocialimg" src="../assets/image/Public Icons/Social/linkedin.png" height="48.8" width="48.8"/>
              </a>
              <a v-if="twitter" :href="'https://twitter.com/'+twitter" target="_blank" class="supportsocial rounded-pill  mb-3" style="border-color: #4ea4f6;color: #4ea4f6">
                <p class="ma-0 ml-9">Twitter</p>
                <img class="supportsocialimg" src="../assets/image/Public Icons/Social/twitter.png" height="48.8" width="48.8"/>
              </a>

            </div>


          </div>
        </ContantBlock>
      </div>
      <div class="contsupp-col">


        <ContantBlock class="mb-8">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon8.png"/>
          <span slot="title" >ارسال پیام</span>
          <div slot="contant" class="contantsupport">

            <v-text-field
                background-color="white"
                label="نام و نام خانوادگی"
                height="50"
                outlined
                v-model="fullName"
                color="#570654"
                class="rounded-0"
            ></v-text-field>

            <v-text-field
                background-color="white"
                label="نام شرکت / مجموعه"
                height="50"
                outlined
                v-model="companyName"
                color="#570654"
                class="rounded-0"
            ></v-text-field>

            <v-text-field
                background-color="white"
                label="ایمیل"
                height="50"
                outlined
                v-model="email"
                color="#570654"
                class="rounded-0"
            ></v-text-field>

            <v-textarea
                background-color="white"
                label="متن پیام"
                outlined
                v-model="text"
                color="#570654"
                class="rounded-0"
            ></v-textarea>

            <v-btn
                class="btn d-block mr-auto mt-6"
                @click="sendmessage"
                :loading="loading"
            >
              ارسال
            </v-btn>

          </div>
        </ContantBlock>

      </div>


    </v-container>
      </div>
    </transition>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import ContantBlock from "@/components/ContantBlock";
import axios from "axios";
export default {
  name: 'App',

  data() {
    return {
      text : '',
      html : '',
      telegram : '',
      whatsapp : '',
      instagram : '',
      linkedin : '',
      aparat : '',
      facebook : '',
      twitter : '',
      email : '',
      companyName : '',
      fullName : '',
      loading : false,
    }
  },
  metaInfo() {
    return {
      title:'پشتیبانی',
      link: [{rel: 'canonical', href: this.$store.state.url+'/support'}]
    }
  },
  components : {
    BreadCrumbs,
    ContantBlock,
  },
  created() {
    axios.get(this.$store.state.api + 'supportpage').then(response=>{
      this.html = response.data.data.html;
      this.telegram = response.data.data.telegram.url;
      this.whatsapp = response.data.data.whatsapp.url;
      this.instagram = response.data.data.instagram.url;
      this.linkedin = response.data.data.linkedin.url;
      this.aparat = response.data.data.aparat.url;
      this.facebook = response.data.data.facebook.url;
      this.twitter = response.data.data.twitter.url;
    });
  },
  methods : {
    sendmessage(){
      this.loading = true;
      axios.post(this.$store.state.api + 'contactus', {
        type : 'support',
        text : this.text,
        email : this.email,
        companyName : this.companyName,
        fullName : this.fullName,
      }).then(response => {
        if(response.data.result){
          this.text = '';
          this.email = '';
          this.companyName = '';
          this.fullName = '';
          this.loading = false;
          this.$store.state.notify.is = true;
          this.$store.state.notify.text = 'پیام شما ارسال شد';
        }
      })
    }
  }
};
</script>

<style lang="scss">

.supportnotice{
  font-size: 18px;
  margin-top: 38px;
  margin-bottom: 0;
  color: #570654;
}

.contantsupport .v-input .v-label{
  color: #a3a3a3 !important;
  font-size: 14px !important;
}

.contantsupport .v-text-field .v-label--active{
  color: #570654 !important;
}

.contantsupport .v-text-field fieldset{
  border-color: #e8e8e8 ;
}

.contantsupport .v-input--is-focused fieldset{
  border-color: #570654 !important;
}

.contantsupport .v-input__slot{
  margin-bottom: 4px !important;
}

.contantsupport .v-text-field__details{
  margin-bottom: 4px !important;
}

.contantsupport .v-input{
  font-size: 14px !important;
}

.supportsocial{
  width: 160px;
  height: 48.8px;
  border: 3px solid #25d366;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #25d366;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.supportsocialimg{
  position: absolute;
  border-radius: 50%;
  left: -3px;
}

.supp-col{
  order: 1;
  max-width: 100%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}

@media only screen and (min-width: 768px) {
  .supp-col{
    order: 0;
    max-width: 33.33%;
    flex-basis: 33.33%;
  }
}

@media only screen and (min-width: 960px) {
  .supp-col{
    order: 0;
    max-width: 29.64601%;
    flex-basis: 29.64601%;
  }
}

.contsupp-col{
  order: 0;
  max-width: 100%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}

@media only screen and (min-width: 768px) {
  .contsupp-col{
    order: 1;
    max-width: 66.66%;
    padding-right: 12px;
    flex-basis: 66.66%;
  }
}

@media only screen and (min-width: 960px) {
  .contsupp-col{
    order: 1;
    max-width: 70.35398%;
    padding-right: 24px;
    flex-basis: 70.35398%;
  }
}


</style>
